<template>
  <section ref="home" :class="`home detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar title="Daily Tarot" :deviceType="deviceType" />
    <section class="home-content ">
      <div class="banner">
        <div class="banner-title">
          Daily Tarot
        </div>
        <div class="banner-txt">
          Get your free daily tarot card readings and personalized insights at Daily Tarot.
        </div>
        <div class="card-select-box">
          <carousel-3d :space="100" :perspective="0" :onMainSlideClick="selectACard">
            <slide
              v-for="(slide, i) in slides"
              :key="i"
              :index="i"
              style="margin: 0 auto;">
              <template slot-scope="{ index, isCurrent }">
                <img :data-index="index" :class="{ current: isCurrent }" :src="slide.src">
              </template>
            </slide>
          </carousel-3d>
        </div>
        <img src="@/assets/m_banner.png" alt="">
        <div class="select-btn" @click="selectACard()">
          Select a card
        </div>
      </div>

      <div class="home-intro-row">
        <!-- <AdComponent ref="ads-home-1" class="m-b-40" :ads="adsensConfig.home_1"
          :showDebug="showDebug" /> -->
        <div class="home-intro-title">
          What's Daily Tarot?
        </div>
        <div class="home-intro-content">
          Daily Tarot is a practice where individuals draw a single tarot card each day to gain insight and guidance. It helps people reflect on their day, set intentions, and connect with their intuition. Many use it for daily mindfulness and clarity.
        </div>

        <!-- <AdComponent ref="ads-home-2" class="m-b-40" :ads="adsensConfig.home_2"
          :showDebug="showDebug" /> -->

        <div class="home-intro-title">
          How to use Daily Tarot?
        </div>
        <div class="home-intro-content">
          To use Daily Tarot, follow these simple steps:
          <br><br>
          1. Set Your Intention: Take a moment to focus on what you want guidance on for the day.
          <br><br>
          2. Draw a Card: When you feel ready, draw a single card from the deck.
          <br><br>
          3. Reflect on the Card: Look at the card and consider its imagery and meaning. Think about how it might relate to your day ahead.
          <br><br>
          4. Journal Your Thoughts: Write down your reflections and any insights you gain from the card.
          <br><br>
          5. Apply the Guidance: Use the card's message to guide your actions and decisions throughout the day.
        </div>

        <!-- <AdComponent ref="ads-home-3" class="m-b-40" :ads="adsensConfig.home_3"
          :showDebug="showDebug" /> -->

        <!-- <AdComponent ref="ads-home-4" class="m-b-40" :ads="adsensConfig.home_4"
          :showDebug="showDebug" /> -->

        <div class="time-page-card-container">
          <div class="home-intro-title m-b-30">
            Recommend Reading
          </div>
          <div class="time-page-card-row">
            <div v-for="(data,i) in card" :key="i" class="time-page-card-item"
              @click="goto(data.routename)">
              <div class="time-page-card-image">
                <img :src="data.img" :alt="data.title">
              </div>
              <div class="time-page-card-content">
                <div class="time-page-card-title">
                  {{ data.title }}
                </div>
                <div class="time-page-card-desc">
                  {{ data.desc }}
                </div>
              </div>
              <div class="time-page-card-btn">
                Read more
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <FootBar :deviceType="deviceType" />
  </section>
</template>

<script>
import common from '@/mixin/common.js';
import HeadBar from '@/pages/components/head.vue';
import FootBar from '@/pages/components/footer.vue';
import Carousel3d from "@/components/Carousel3d.vue";
import Slide from "@/components/Slide.vue";
import AdComponent from '@/pages/components/AdComponent.vue'

import { mapGetters } from 'vuex';
import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    HeadBar,
    FootBar,
    Carousel3d,
    Slide,
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      card: [
        {
          img: require('@/assets/blog1-1.png'),
          title: 'The History and Origins of Tarot Cards',
          desc: "The history of tarot cards is filled with mystery and debate. Although tarot is now closely associated with mysticism and divination, it didn’t begin as a tool for fortune-telling but as a card game. By tracing the history of tarot, we can see how it evolved from an entertainment tool into the spiritual instrument widely recognized today.",
          routename: 'blog1'
        },
        {
          img: require('@/assets/blog2-1.png'),
          title: "Interpreting Tarot—The Deeper Meanings of the Major and Minor Arcana",
          desc: "Tarot interpretation goes beyond simple fortune-telling, incorporating symbolism, culture, and psychology. The tarot deck is divided into two parts: the Major Arcana and the Minor Arcana, each with its own unique significance and depth.",
          routename: 'blog2'
        },
        {
          img: require('@/assets/blog3-1.png'),
          title: 'Tarot Reading Techniques—How to Perform Accurate Tarot Interpretations',
          desc: 'Tarot reading goes beyond just flipping a few cards, observing patterns, and symbols; it involves understanding the deeper symbolism of the cards and connecting spiritually with the querent. A skilled tarot reader not only needs to be familiar with the symbolism of each card but also needs to develop a keen intuition. Below, we will explore techniques from basic skills to more advanced methods to enhance the accuracy of tarot readings.',
          routename: 'blog3'
        },
        {
          img: require('@/assets/blog4-1.png'),
          title: 'The Integration of Tarot and Astrology: Exploring the Resonance Between Star Signs and Tarot Cards',
          desc: `The integration of tarot and astrology offers a captivating exploration of how these two ancient divination systems can enhance and complement each other. Both tarot and astrology have served as tools for insight and self-discovery for centuries. Although they each employ distinct methodologies, their combination can provide a richer and more nuanced understanding of one's life journey. This article delves into how tarot and astrology intersect, their shared symbols, and how their integration can deepen spiritual and psychological insights.`,
          routename: 'blog4'
        },
      ],
      slides: [
        {
            title: 'Slide 1',
            src: require('@/assets/tarot-card.png')
        },
        {
            title: 'Slide 2',
            src: require('@/assets/tarot-card.png')
        },
        {
            title: 'Slide 3',
            src: require('@/assets/tarot-card.png')
        },
        {
            title: 'Slide 4',
            src: require('@/assets/tarot-card.png')
        },
        {
            title: 'Slide 5',
            src: require('@/assets/tarot-card.png')
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig"

    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本

  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    // #region 广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log("adsensConfig: ",this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');
    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
      }, 500);
    },
    async displayAd () {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    // #endregion 广告相关end
    
    selectACard () {
      this.goto('meaning')
    },
    goto (name) {
      this.$router.push({ name: name });
    },
  }
}
</script>
